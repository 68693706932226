import logo from './logo.svg'
import './App.css'
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a className="App-link" href="https://layme.uk" target="_blank" rel="noopener noreferrer">
          Learn Cloud with me
        </a>
      </header>
      <AmplifySignOut />
    </div>
  )
}

export default withAuthenticator(App)
